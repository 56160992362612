import React from 'react';
import styled from 'styled-components';
import { Navbar, Nav } from 'react-bootstrap';
import SideMenu from '../SideMenu';
import Logo from '../Logo';

const Header = () => {
  return (
    <StyledHeader>
      <Navbar variant="dark" fixed="top">
        <div className="w_100">
          <Nav>
            <Logo />
          </Nav>
          <Nav>
            <SideMenu />
          </Nav>
        </div>
      </Navbar>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  .navbar-dark {
    position: fixed;
    background: linear-gradient(rgba(80, 80, 80, 0.5), rgba(80, 80, 80, 0.5)), url(${require('../../assets/images/simplebrickwall.png')});
    height: 80px;
    width: 100%;
    z-index: 99;

    border-bottom-style: outset;
    border-width: 4px;

    .w_100 {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      align-content: space-between;
      flex-direction: row;

      .navbar-nav {
        margin-top: 4px;
        color: white;
        flex-direction: column;
        flex: 1;

        :nth-child(odd) {
          align-items: flex-start;
        }

        :nth-child(even) {
          align-items: flex-end;
        }
      }
    }
  }
`;

export default Header;
