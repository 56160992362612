export const DESC_GENERAL_PAGE = 'Golden Beard Games is an independent game developer based in Tokyo, Japan, bringing you the fun classic-style games we all love to play!';

/* Game pages */
export const DESC_BRAVE_DWARF = 'Brave Dwarf is an exciting retro action-adventure game developed by Golden Beard Games!';
export const DESC_DWARF_TENNIS = 'Dwarf Tennis is a secret page you shouldn\'t be sharing! Developed by Golden Beard Games!';

export const SOCIAL_LINK_FACEBOOK = 'https://www.facebook.com/GoldenBeardGames';
export const SOCIAL_LINK_TWITTER = 'https://twitter.com/G_B_G_Official';
export const SOCIAL_LINK_YOUTUBE = 'https://www.youtube.com/channel/UCCbTo7OcncdyxQ6KVHAMQtg/';

export const OST_PREVIEW_LINK = 'https://www.youtube.com/watch?v=f1s6ZHjjLQU&list=PL7la5n4WcWny-a16nnGxJxad3nvg2lz76';

export const SCREENSHOTS = [
  {
    original: require('../assets/images/screenshots/brave-dwarf-screen-1.png'),
    thumbnail: require('../assets/images/screenshots/brave-dwarf-screen-1.png'),
  },
  {
    original: require('../assets/images/screenshots/brave-dwarf-screen-2.png'),
    thumbnail: require('../assets/images/screenshots/brave-dwarf-screen-2.png'),
  },
  {
    original: require('../assets/images/screenshots/brave-dwarf-screen-3.png'),
    thumbnail: require('../assets/images/screenshots/brave-dwarf-screen-3.png'),
  },
  {
    original: require('../assets/images/screenshots/brave-dwarf-screen-4.png'),
    thumbnail: require('../assets/images/screenshots/brave-dwarf-screen-4.png'),
  },
  {
    original: require('../assets/images/screenshots/brave-dwarf-screen-5.png'),
    thumbnail: require('../assets/images/screenshots/brave-dwarf-screen-5.png'),
  },
  {
    original: require('../assets/images/screenshots/brave-dwarf-screen-6.png'),
    thumbnail: require('../assets/images/screenshots/brave-dwarf-screen-6.png'),
  },
  {
    original: require('../assets/images/screenshots/brave-dwarf-screen-7.png'),
    thumbnail: require('../assets/images/screenshots/brave-dwarf-screen-7.png'),
  },
  {
    original: require('../assets/images/screenshots/brave-dwarf-screen-8.png'),
    thumbnail: require('../assets/images/screenshots/brave-dwarf-screen-8.png'),
  },
];
