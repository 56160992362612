import React from 'react';
import styled from 'styled-components';
import { A } from 'hookrouter';

const Logo = () => (
  <StyledLogo>
    <A href="/">
      <img className="logo" src={require('../../assets/images/gbg-logo.gif')} alt="Logo" /><img className="logo-text" src={require('../../assets/images/gbg-logo-text.png')} alt="Golden Beard Games" />
    </A>
  </StyledLogo>
);

const StyledLogo = styled.div`
  .logo{
    width: 80px;
    margin-left: 30px;
  }
  .logo-text{
    width: 200px;
    margin: auto 0px;
  }

  img{
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  a:hover{
    .logo{
      width: 90px;
      margin-left: 20px;
    }
    .logo-text{
      width: 220px;
    }
  }

  @media screen and (max-width: 680px) {
    .logo-text {
      display: none;
    }

    a:hover{
      .logo{
        width: 90px;
        margin-left: 25px;
      }
    }
  }
`;

export default Logo;
