import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'hookrouter';
import DwarvenHelmet from '../../components/DwarvenHelmet';
import { DESC_GENERAL_PAGE } from '../../utils/constants';

const Error404 = () => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (countdown > 0){
      setTimeout(() => {
        setCountdown(countdown-1);
      }, 1000);
    } else {
      setTimeout(() => {
        navigate('/')
      }, 500);
    }
  }, [countdown])

  return (
    <StyledError404>
      <DwarvenHelmet title={'Page Not Found - Golden Beard Games'} description={DESC_GENERAL_PAGE}/>
      <div className="error-block">
        <div>AND THE PAGE IS.. MISSING?!</div>
        <div className='href-parent'><span className='four-oh-four'>404</span><img src={require('../../assets/images/error-404.png')} alt="Error 404"></img><a href={'/'+process.env.REACT_APP_SECRET_LAIR}>Urist</a></div>
        {countdown <= 3 ?<div>BUT DON'T WORRY, WE'LL SET YOU<br/><br/>BACK ON TRACK IN<br/><br/> {countdown}</div>:null}
      </div>
    </StyledError404>
  );
};

const StyledError404 = styled.div`
  @font-face {
     font-family: 'Pixel';
     src: url(${require('../../assets/fonts/prstartk.ttf')}) format('truetype');
   }
  min-height: calc(100vh - 263px);
  font-family: 'Pixel';
  padding-top: 84px;
  color: white;
  font-size: 16px;
  background-color: black;
  text-align: center;

  .error-block{
    margin-top: 5%;

    div {
      padding: 16px 0px;
    }
    img {
      width: 540px;
    }

    a {
      position: absolute;
      width:50px;
      height:50px;
      right: 134px;
      top: 110px;
      cursor: none;
      font-size: 4px;
      color: black;
      text-decoration: none;
    }

    .href-parent{
      position:relative;
      width: 550px;

      margin 0 auto;
    }

    .four-oh-four {
      position: absolute;
      left: 12px;
      top: 135px;
      background: black;
      width: 60px;
      height: 30px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 560px){
  .error-block{
    img {
      width: 420px;
    }

    a {
      display: none;
    }

    .href-parent{
      width: 420px;
    }

    .four-oh-four{
      left: 0px;
      top: 100px;
    }
  }
}

@media screen and (max-width: 460px){
  .error-block{
    img {
      width: 360px;
    }

    .href-parent{
      width: 360px;
    }

    .four-oh-four{
      left: -5px;
      top: 90px;
    }
  }
}

@media screen and (max-width: 375px){
  .error-block{
    img {
      width: 240px;
    }

    .href-parent{
      width: 240px;
    }

    .four-oh-four{
      left: -14px;
      top: 54px;
    }
  }
}
`;

export default Error404;
