import React, { useEffect, lazy } from 'react';
import styled from 'styled-components';
import { useRoutes/*, usePath, navigate */} from 'hookrouter';
import ReactGA from 'react-ga';
import Error404 from '../../pages/Error404';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { DESC_BRAVE_DWARF, DESC_DWARF_TENNIS } from '../../utils/constants';
const HomePage = lazy(() => import('../../pages/HomePage'));
const GamePage = lazy(() => import('../../pages/GamePage'));
const AboutPage = lazy(() => import('../../pages/AboutPage'));
const ContactPage = lazy(() => import('../../pages/ContactPage'));

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO);

const appRoutes = {
  '/': () => <HomePage />,
  '/home': () => <HomePage />,
  '/top': () => <HomePage />,
  '/games/bravedwarf': () => <GamePage game={"Brave Dwarf"} description={DESC_BRAVE_DWARF}/>,
  '/about': () => <AboutPage/>,
  '/contact': () => <ContactPage/>,
  ['/'+process.env.REACT_APP_SECRET_LAIR]: () => <GamePage game={"Dwarf Tennis"} description={DESC_DWARF_TENNIS}/>,
};

// const PATHS_ADMIN = [];


const AppRouter = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);

    //shouldn't need this but Unity WebGL doesn't properly unload without running in an iframe and iframes suck
    //TODO: find a better solution for this later
    let lastPage = window.sessionStorage.getItem('lastPage');
    if (lastPage === '/'+process.env.REACT_APP_SECRET_LAIR && lastPage !== window.location.pathname){
      window.sessionStorage.setItem('lastPage', window.location.pathname);
      window.location.reload();
    } else {
      window.sessionStorage.setItem('lastPage', window.location.pathname);
    }
  });

  // const path = usePath();

  // const showHeader = appRoutes.hasOwnProperty(path);
  // const showFooter = appRoutes.hasOwnProperty(path);

  // const showFooter = (PATHS_HIDE_FOOTER.indexOf(path) === -1); //hide footer component for specified routes

  return (
    <StyledAppRouter>
      {/*showHeader && */<Header />}
      {useRoutes(appRoutes) || <Error404 />}
      {/*showFooter && */<Footer />}
    </StyledAppRouter>
  );
};

const StyledAppRouter = styled.div`
  height: 100%;
`;

export default AppRouter;
