import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import LoadingWidget from './components/LoadingWidget';
import MaintenancePage from './pages/MaintenancePage';

ReactDOM.render(
  (process.env.REACT_APP_DWARVES_AT_WORK === 'true') ? (
    <MaintenancePage />
  ) : (
    <Suspense fallback={<LoadingWidget />}>
      <App />
    </Suspense>
  ),
  document.getElementById('root'),
);

serviceWorker.register();
