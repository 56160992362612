import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import useOnclickOutside from 'react-cool-onclickoutside';
import { navigate } from 'hookrouter';
import Hexagon from 'react-hexagon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { SOCIAL_LINK_FACEBOOK, SOCIAL_LINK_TWITTER, SOCIAL_LINK_YOUTUBE } from '../../utils/constants';

const cssConstants = {
  mediumBiggerSize: '16px',
  whiteColor: '#fff',
  bgHeader2: '#353333',
  bigSize: '20px',
  biggerSize: '25px',
  blackColor: '#000',
  bgColor: '#292828',
  bgHeader: '#2e2d2d',
};

const handleMenuItemClick = href => {
  navigate(href);
};

const MenuItem = ({ onClick, setShowSidebar, href, firstLetter, text }) => (
  <li
    onClick={() => {
      onClick ? onClick() : handleMenuItemClick(href);
      setShowSidebar(false);
    }}
  >
    <Hexagon className="rune">
      <text x="50%" y="70%">{firstLetter}</text>
    </Hexagon>
    <span className="menu-link-text">{text}</span>
  </li>
);

const MenuSocial = ({ onClick, setShowSidebar, href, socialIcon, text, horizontalShift }) => (
  <li
    onClick={() => {
      window.open(href, 'gbg-'+text.toLowerCase());
      setShowSidebar(false);
    }}
  >
    <Hexagon className="rune">
      <FontAwesomeIcon className="rune-social" icon={socialIcon} viewBox={"0 -192 " + horizontalShift + " 896"}/>
    </Hexagon>
    <span className="menu-link-text">{text}</span>
  </li>
);

const SideMenuItems = (showSidebar, setShowSidebar) => {

  return (
    <div id="sidebar" className={showSidebar ? 'show' : ''} >
      <div className="link-wrapper">
        <ul>
          <MenuItem
            backgroundColor="#21b64c"
            firstLetter="h"
            text="HOME"
            href="/"
            setShowSidebar={setShowSidebar}
          />
          <MenuItem
            backgroundColor="#21b64c"
            firstLetter="b"
            text="BRAVE DWARF"
            href="/games/bravedwarf"
            setShowSidebar={setShowSidebar}
          />
          <MenuItem
            backgroundColor="#21b64c"
            firstLetter="a"
            text="ABOUT US"
            href="/about"
            setShowSidebar={setShowSidebar}
          />
          <MenuItem
            backgroundColor="#21b64c"
            firstLetter="c"
            text="CONTACT"
            href="/contact"
            setShowSidebar={setShowSidebar}
          />
          {/*<MenuItem
            backgroundColor="#21b64c"
            firstLetter="P"
            text="Press Kit"
            href="/press-kit"
            setShowSidebar={setShowSidebar}
          />*/}
        </ul>
      </div>
      <hr className="menu-divider" />
      <div className="link-wrapper">
        <ul>
          <MenuSocial
            backgroundColor="#21b64c"
            socialIcon={faFacebookF}
            horizontalShift={320}
            text="FACEBOOK"
            href={SOCIAL_LINK_FACEBOOK}
            setShowSidebar={setShowSidebar}
          />
          <MenuSocial
            backgroundColor="#21b64c"
            socialIcon={faTwitter}
            horizontalShift={480}
            text="TWITTER"
            href={SOCIAL_LINK_TWITTER}
            setShowSidebar={setShowSidebar}
          />
          <MenuSocial
            backgroundColor="#21b64c"
            socialIcon={faYoutube}
            horizontalShift={578}
            text="YOUTUBE"
            href={SOCIAL_LINK_YOUTUBE}
            setShowSidebar={setShowSidebar}
          />
        </ul>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const ref = useRef();

  useOnclickOutside(ref, () => {
    setShowSidebar(false);
  });

  return (
    <StyledSidebar ref={ref} {...cssConstants}>
      <button className="dwarven-hamburger" aria-label="button-open" onClick={() => setShowSidebar(!showSidebar)}>
        <FontAwesomeIcon icon={showSidebar ? faTimes : faBars} />
      </button>
      {SideMenuItems(showSidebar, setShowSidebar)}
    </StyledSidebar>
  );
};

const StyledSidebar = styled.div`
  z-index: 10;

  @font-face {
   font-family: 'Runes';
   src: url(${require('../../assets/fonts/RUNE_D.ttf')}) format('truetype');
 }

  button {
    cursor: pointer;
  }

  #sidebar {
    border-left: 2px solid rgb(188, 188, 188);

    position: fixed;
    right: -375px;
    top: 84px;
    height: 100%;
    width: 250px;
    background-color: black;
    color: ${({ whiteColor }) => whiteColor};

    -webkit-transition: all 0.5s !important;
    -moz-transition: all 0.5s !important;
    -o-transition: all 0.5s !important;
    transition: all 0.5s !important;

    .menu-divider {
      border: 2px solid rgb(188, 188, 188);
      border-radius: 5px;
      margin: 0 80px;
    }
  }

  .show {
    right: 0px !important;

    -webkit-transition: all 0.25s !important;
    -moz-transition: all 0.25s !important;
    -o-transition: all 0.25s !important;
    transition: all 0.25s !important;
  }

  .menu-link-text {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: rgb(248, 248, 248);
    float: right;
    text-align: left;
    width: 75%;
    line-height: 40px;

    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .link-wrapper {
    margin-top: 20px;
    width: 100%;

    ul {
      list-style: none;
      width: 100%;
    }

    ul li {
      margin-left: -40px;
      width: 230px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 5px 10px;
      font-weight: bold;
      cursor: pointer;

      -webkit-transition: all 0.2s, color 0.5s;
      -moz-transition: all 0.2s, color 0.5s;
      -o-transition: all 0.2s, color 0.5s;
      transition: all 0.2s, color 0.5s;

      :hover {
        background-color: rgb(0, 64, 88);

        .rune{
          fill: rgb(0, 252, 252);
          color: rgb(0, 252, 252);
        }

        .menu-link-text{
          font-size: 22px;
        }
      }

      :active {
        .menu-link-text{
          font-size: 20px;
          color: rgb(188, 188, 188);
        }
      }
    }

  }

  .selected {
    background-color: hsla(0, 0%, 100%, 0.06);
  }

  .rune {
    width: 40px;
    height: 40px;
    display: inline-block;

    pointer-events: none;
    text-anchor: middle;
    font-family: "Runes";
    font-size: 225px;
    fill: rgb(120, 120, 120);
    color: rgb(120, 120, 120);
    alignment-baseline: middle;

    -webkit-transition: all 0.2s, color 0.5s, fill 0.5s;
    -moz-transition: all 0.2s, color 0.5s, fill 0.5s;
    -o-transition: all 0.2s, color 0.5s, fill 0.5s;
    transition: all 0.2s, color 0.5s, fill 0.5s;

    polygon{
      stroke: rgb(120, 120, 120) !important;
      stroke-width: 30px !important;
      fill: rgb(188, 188, 188) !important;
    }

    .rune-social{
      font-family: 'Fa solid 900',sans-serif;
    }
  }

  .dwarven-hamburger {
    position: fixed;
    top: 0px;
    right: 15px;
    width: 50px;
    height: 50px;
    margin: 15px;
    padding: 0 10px;
    background-color: rgb(188, 188, 188);
    box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.61);
    border-style: outset;
    font-family: 'Fa solid 900', sans-serif;
    color: rgb(120, 120, 120);
    font-size: 22px;
    z-index: 100;

    -webkit-transition: all 0.2s, color 0.5s;
    -moz-transition: all 0.2s, color 0.5s;
    -o-transition: all 0.2s, color 0.5s;
    transition: all 0.2s, color 0.5s;

    :hover {
      width: 55px;
      height: 55px;
      top: -2px;
      right: 13px;
      font-size: 24px;
      color: rgb(0, 252, 252);
      box-shadow: 4px 6px 4px 0 rgba(0, 0, 0, 0.61);
    }

    :active{
      top: 0px;
      right: 15px;
      width: 50px;
      height: 50px;
      font-size: 22px;
      background-color: rgb(120, 120, 120);
      box-shadow: none;
      border-style: inset;
      color: rgb(0, 232, 216);
      outline: 0;
    }

    :focus {
      outline:0;
    }
  }

  @media screen and (max-width: 375px) {

    #sidebar {
      width: 100%;
      right: -375px;

      .link-wrapper > ul > li{
        margin-left: 20px;
      }

    }

  @media screen and (max-width: 300px) {

    #sidebar > .link-wrapper > ul > li{
      margin-left: -20px;
    }

  }

`;

export default Sidebar;
