import React from 'react';
import { Helmet } from 'react-helmet';

const DwarvenHelmet = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="theme-color" content="#787878" />
    {/* For Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.goldenbeardgames.com/" />
    <meta property="og:site_name" content="Golden Beard Games" />
    <meta property="og:image" content="https://www.goldenbeardgames.com/goldenbeard-ogi.png" />
    <meta property="og:title" content={title} />
    <meta name="og:description" content={description} />
    {/* For Twitter */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content="https://www.goldenbeardgames.com/goldenbeard-ogi.png" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:url" content="https://www.goldenbeardgames.com/" />
  </Helmet>
);

export default DwarvenHelmet;
