import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { SOCIAL_LINK_FACEBOOK, SOCIAL_LINK_TWITTER, SOCIAL_LINK_YOUTUBE } from '../../utils/constants';

const Footer = () => (
  <StyledFooter>
  <div className="social-links">
    <a className="social-button" href={SOCIAL_LINK_FACEBOOK} target="gbg-facebook"><FontAwesomeIcon icon={faFacebookF}/></a>
    <a className="social-button" href={SOCIAL_LINK_TWITTER} target="gbg-twitter"><FontAwesomeIcon icon={faTwitter}/></a>
    <a className="social-button" href={SOCIAL_LINK_YOUTUBE} target="gbg-youtube"><FontAwesomeIcon icon={faYoutube}/></a>
  </div>
  <div className="copyright">
    © 2018 - {moment().year()} Golden Beard Games
  </div>
  </StyledFooter>
);

const StyledFooter = styled.footer`
  position: relative;
  width: 100%;
  bottom: 0px;
  padding: 30px 0px;
  background: black;
  color: rgb(248, 248, 248);
  font-family: 'Roboto', sans-serif;
  border-top: 2px solid rgb(188, 188, 188);

  text-align: center;

  .social-links{
    width:100%;
    max-width: 420px;
    height: 100px;
    margin: 0 auto;

    .social-button{
      -webkit-transition: all 0.2s !important;
      -moz-transition: all 0.2s !important;
      -o-transition: all 0.2s !important;
      transition: all 0.2s !important;


      display: inline-block;
      margin: 0 auto;
      font-size: 24px;
      text-decoration: none;
      color: rgb(248, 248, 248);
      width: 33.33333%;

      padding-top: 20px;

      :hover{
        font-size: 32px;
        padding-top: 0px;
      }

      :active{
        font-size: 24px;
        padding-top: 20px;

        color: rgb(120, 120, 120);
      }
    }
  }

  .copyright {
    font-size: 14px;
    color: rgb(188, 188, 188);
  }
`;

export default Footer;
