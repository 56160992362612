import React from 'react';
import styled from 'styled-components';
import AppRouter from './components/AppRouter';

const App = () => {
  return (
    <StyledApp>
      <AppRouter />
    </StyledApp>
  );
};

const StyledApp = styled.div`
  background-image: url(${require('./assets/images/gbg-background.png')});
  background-size: 100%;
  background-color: #f8f8f8;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
`;

export default App;
