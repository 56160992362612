import React from 'react';
import styled from 'styled-components';
import DwarvenHelmet from '../../components/DwarvenHelmet';
import { DESC_GENERAL_PAGE } from '../../utils/constants';

const MaintenancePage = () => {
  return (
    <StyledMaintenancePage>
      <DwarvenHelmet title={'Dwarves At Work - Golden Beard Games'} description={DESC_GENERAL_PAGE}/>
      <h1>Maintenance Page</h1>
    </StyledMaintenancePage>
  );
};

const StyledMaintenancePage = styled.div`
  min-height: calc(100vh - 263px);
  padding-top: 84px;
  text-align: center;
`;

export default MaintenancePage;
