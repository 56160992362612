import React from 'react';
import styled from 'styled-components';

const LoadingWidget = () => (
  <StyledLoadingWidget>
    <div><span>LOADING...</span></div>
  </StyledLoadingWidget>
);

const StyledLoadingWidget = styled.div`
    @font-face {
     font-family: 'Pixel';
     src: url(${require('../../assets/fonts/prstartk.ttf')}) format('truetype');
    }

    font-family: 'Pixel';
    font-size: 16px;
    color: rgb(248, 248, 248);

    background-color: black;

    height: 100vh;
    width: 100vw;

    div{
      position: absolute;
      width: 100%;
      top: 50%;

      text-align: center;
    }

`;

export default LoadingWidget;
